import React, { useState, useEffect } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TrackingPage from "./TrackingPage";
import { useHistory } from "react-router-dom";
import Search from "./Search";
import { useInitContext, OrderContext } from "../context";
import { useQuery } from "react-query";

const TrackingApp = () => {
  const context = useInitContext();
  const { setOrder, setReferenceOrders, order, error, setError } = context;
  const { tracking, postcode } = useParams();
  const cleanedPostcode = postcode?.replace(/\s*/g, "") ?? "";
  const queryKey = ["trackingOrder", tracking, cleanedPostcode];

  const history = useHistory();

  const {
    isError,
    error: queryError,
    isLoading,
    data,
  } = useQuery(
    queryKey,
    async () => {
      if (!tracking || !postcode) {
        throw new Error("Tracking or postcode is undefined");
      }
      return getRequestUI("/order/search", {
        trackingCode: tracking,
        zipcode: cleanedPostcode,
      });
    },
    {
      enabled: !!tracking && !!cleanedPostcode,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { mainOrder, referencedOrders } = data;
        if (
          mainOrder.trackingLink.mode === "redirect" &&
          mainOrder.trackingLink.redirectTrackingLink
        ) {
          window.location.replace(mainOrder.trackingLink.redirectTrackingLink);
          return;
        }
        if (
          mainOrder.chatbox &&
          window.Trengo.Api &&
          window.Trengo.Api.Widget
        ) {
          window.Trengo.Api.Widget.render();
        }
        if (referencedOrders && referencedOrders.length) {
          setReferenceOrders(referencedOrders);
        }
        setOrder(mainOrder);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );

  useEffect(() => {
    if (isError) {
      onError(queryError);
    }
  }, [isError]);

  const onError = (error) => {
    history.push("/");
    if (error instanceof Error) {
      return;
    }
    setError(error);
  };

  const isEmptyData = !tracking || !postcode;
  return (
    <OrderContext.Provider value={context}>
      {isLoading && !isEmptyData && <CircularProgress sx={styles.spinner} />}
      {order && <TrackingPage />}
      {isEmptyData && (
        <Search tracking={tracking} postcode={postcode} error={error} />
      )}
    </OrderContext.Provider>
  );
};

const styles = {
  spinner: {
    right: "50%",
    top: "50%",
    position: "absolute",
  },
};

export default TrackingApp;
